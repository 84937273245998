.ftr-links-koteret{
  font-size: 17px;
  font-weight: 700;
  color: $main-white;
  margin-bottom: 25px;
  position: relative;
  @include respond-to('extra-small'){
    font-size: 14px;
    margin-bottom: 5px;
    padding-bottom: 10px;
  }
}

.ftr-wrap-links{
  max-width: 200px;
  padding-#{$right}: 50px;
  text-align: #{$right};
  @include respond-to('1100'){
    padding-#{$right}: 0px;
  }
  @include respond-to('small'){
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-to('extra-small'){
    margin-bottom: 15px;
  }
  & > ul{
    padding: 0;
    margin: 0;
    list-style: none;
    & > li{
        margin-bottom: 5px;
        @include respond-to('extra-small'){
          margin-bottom: 0px;
        }
      & > a{
        font-size: 14px;
        font-weight: 300;
        color: $main-white;
      }
    }
  }
}