.breadcrumb {
  display: inline-block;
  padding: 60px 0px;
  margin-bottom: 0;
  border-radius: 0;
  background-color: transparent;
  color: #000;
  @include respond-to('medium') {
    padding: 20px 0;
  }
  @include respond-to('extra-small') {
    padding: 5px 0;
  }
  & a {
    text-decoration: none;
    color: #000;
    font-size: 15px;
    font-weight: 300;
    &:hover {
      text-decoration: none;
      color: #777777;
    }
  }
  & .active {
    color: #f0c911;
    font-weight: 700;
    font-size: 15px;
  }
}

.breadcrumb>li+li:before {
  color: #000;
}