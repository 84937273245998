.banenr-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .33;
}
.wrapper-all-content {
  background-color: $main-white;
  position: relative;
  width: 100%;
}

.wrap-homepage-categ {
  padding-bottom: 84px;
  position: relative; //max-height: 1325px;
  @include respond-to('medium') {
    padding-bottom: 0px;
  }
}

.decor-elem {
  position: absolute;
  z-index: 10;
  &_pos-top {
    top: -25px;
    left: 0;
    @include respond-to('1100') {
      display: none;
    }
  }
  &_pos-bot {
    bottom: -145px;
    right: 0;
    @include respond-to('1100') {
      display: none;
    }
  }
}

@media screen and (max-width: 1630px) {
  .decor-elem_pos-bot {
    width: 300px;
  }
}

@media screen and (max-width: 1320px) {
  .decor-elem_pos-bot {
    bottom: -65px;
  }
}

.homepage__buttons {
  padding: 65px 0px 126px;
  @include respond-to('medium') {
    padding: 35px 0px 66px;
  }
  @include respond-to('extra-small') {
    padding: 25px 0px 25px;
  }
}

.hp-buttons-title {
  margin: 0 0 80px;
  color: $main-red;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  @include respond-to('medium') {
    margin: 0 0 35px;
    font-size: 40px;
  }
  @include respond-to('extra-small') {
    margin: 0 0 25px;
    font-size: 30px;
  }
  &_white {
    color: $main-white;
  }
  &_black {
    color: #000;
    margin-bottom: 0;
  }
}

.hp-buttons-subtitle {
  text-align: center;
  margin: 0 0 40px;
  font-size: 50px;
  font-weight: 300;
  color: #fff;
  @include respond-to('medium') {
    margin: 0 0 35px;
    font-size: 40px;
  }
  @include respond-to('extra-small') {
    margin: 0 0 25px;
    font-size: 25px;
  }
}

.homepage-categ {
  position: relative;
  display: block;
  margin-bottom: 45px;
  max-width: 375px;
  width: 100%;
  color: $main-white;
  @include respond-to('medium') {
    margin: 0 auto 15px;
  }
  &_height1 {
    height: 509px;
    @include respond-to('small') {
      height: 250px;
    }
  }
  &_height2 {
    height: 286px;
    @include respond-to('small') {
      height: 250px;
    }
  }
  &_height3 {
    height: 423px;
    margin-bottom: 30px;
    @include respond-to('small') {
      height: 250px;
    }
  }
  &_height4 {
    height: 197px;
    margin-bottom: 30px;
    @include respond-to('small') {
      height: 250px;
    }
  }
  &_height5 {
    height: 161px;
    @include respond-to('small') {
      height: 250px;
    }
  }
  &_height6 {
    height: 227px;
    @include respond-to('small') {
      height: 250px;
    }
  }
  &_height7 {
    height: 570px;
    @include respond-to('small') {
      height: 250px;
    }
  }
  &:hover {
    & .hover-shadov {
      opacity: 1;
      outline: 1px solid #b1b1b1;
      outline-offset: -22px;
    }
    & .homepage-categ-info {
      display: none;
    }
  }
  &:focus {
    color: $main-white;
    text-decoration: none;
  }
  &__image {
    overflow: hidden;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    img {
      transition: transform 200ms;
    }
  }
  &__title {
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 10px;
  }
}

.homepage-categ-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 40px;
  @include respond-to('large') {
    padding: 10px;
  }
}

.book-wrap {
  text-align: center;
}

.book-item {
  display: inline-block;
  margin: 0 45px;
  @include respond-to('large') {
    margin: 5px 25px;
  }
  @include respond-to('small') {
    margin: 10px auto;
  }
  & img {
    box-shadow: -10px 10px 30px 0px rgba(0, 0, 0, 0.4);
  }
}

.homepage-form-wrap {
  position: relative;

  & .homepage__buttons {
    padding: 65px 0px;
    @include respond-to('medium') {
      padding: 35px 0 66px;
    }
    @include respond-to('extra-small') {
      padding: 25px 0;
    }
  }
}
