.wrapper-banners {
  position: relative;
  width: 100%;
  height: 955px;
  overflow: hidden;
  @include respond-to('huge') {
    height: 650px;
  }
  @include respond-to('large') {
    height: 450px;
  }
  @include respond-to('extra-small') {
    height: 300px;
  }
}

.wrap-banner-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include respond-to('medium') {
    position: static;
  }
}

.banner-item {
  width: 100%;
  min-height: 955px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include respond-to('huge') {
    min-height: 650px;
  }
  @include respond-to('large') {
    min-height: 450px;
  }
  @include respond-to('extra-small') {
    min-height: 300px;
  }
}

.banner-item-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  //max-width: 690px;
  width: 90%;
  color: $main-white;
  text-align: center;
  &__title {
    font-size: 110px;
    font-weight: 400;
    margin: 0;
    line-height: 0.9;
    top: 20px;
    opacity: 0;
    transition: 1s;
    position: relative;
    @include respond-to('medium') {
      font-size: 50px;
    }
    @include respond-to('extra-small') {
      font-size: 30px;
      font-weight: 700;
    }
  }
  &__subtitle {
    font-size: 18px;
    letter-spacing: 10px;
    font-weight: 700;
    margin: 10px 0 0;
    transition: 1s;
    position: relative;
    top: -20px;
    @include respond-to('extra-small') {
      letter-spacing: 18px;
    }
    &:first-letter {
      letter-spacing: 0px;
    }
  }
}
.slick-active{
  .banner-item-text{
    &__title{
      top: 0;
      opacity: 1;
    }
    &__subtitle {
      top: 0;
    }
  }
}
