.wrapper-all-content.category-backgr {
  background-color: #fcfbf3;
  padding-bottom: 195px;
  @include respond-to('medium') {
    padding-bottom: 50px;
  }
  @include respond-to('extra-small') {
    padding-bottom: 25px;
  }
}

.category-box-item {
  float: $right;
  height: 300px;
  width: calc(33% - 15px);
  background-position: center;
  background-size: cover;
  margin: side-values(0 0px 15px 15px);
  &:nth-child(3n) {
    margin: 0 0 15px;
  }
  & .homepage-box {
    max-width: 287px;
    width: 100%;
    max-height: 300px;
    text-align: $right;
    margin: 0 auto;
    &__overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, #000 15%, transparent);
      opacity: .5;
    }
    &__description {
      font-size: 14px;
    }
    &__date {
      font-size: 26px;
    }
    &__time {
      font-size: 16px;
      margin-top: 10px;
    }
  }
  & .homepage-box-info {
    padding: 20px 30px;
    @include respond-to('extra-small') {
      padding: 10px;
    }
  }
  & .hover-shadov {
    background-color: rgba(211, 50, 65, .95);
    &__plus {
      width: 35px;
      background-image: if-rtl(url('../images/hover-arrow.png'), url('../images/hover-arrow-right.png'));
    }
  }
}
@media screen and (max-width: 1228px) {
  .category-box-item-wrap {
    text-align: center;
  }
  .category-box-item {
    float: none;
    display: inline-block;
    margin: 7px 5px;
  }
  .category-box-item:nth-child(3n) {
    margin: 7px 5px;
  }
  .category-box-item {
        width: calc(50% - 15px);
            height: 250px;
  }
}
