.wrap-content {
  padding: 0;
}

.show-page {
  background-color: #fcfbf3;
  & p {
    padding-#{$left}: 0;
    text-align: justify;
  }
}

.show-content-text {
  text-align: center;
  h2,
  h3,
  .show-title {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.2;
    margin: 30px 0 10px;
    text-align: $right;
    @include respond-to('small') {
      margin: 5px 0 15px;
    }
  }
  p {
    font-size: 14px;
    font-weight: 300;
    color: #000;
    line-height: 1.2;
  }
  iframe {
    margin: 0 auto;
    @include respond-to('small') {
      text-align: center !important;
      margin: 15px auto !important;
      width: 100% !important;
      height: auto !important;
      float: none !important;
      display: block !important;
    }
  }
  & img {
    text-align: center !important;
    @include respond-to('small') {
      float: none !important;
      text-align: center !important;
      width: auto !important;
      height: auto !important;
      margin: 15px auto !important;
    }
  }

  &_content-page{
    padding-bottom: 100px;
  }
}

.shows-list-wrap {
  margin: 20px 0 60px;
}

.show-list-item {
  border-top: 3px solid #000;
  position: relative;
  &:last-child {
    border-bottom: 3px solid #000;
  }
}

.item-info {
  float: $right;
  &__title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    margin: 20px 0;
    text-align: $right;
    max-width: 600px;
    @include respond-to('small') {
      margin: 5px 0;
      max-width: 300px;

    }
    @include respond-to('extra-small') {
      max-width: 170px;
    }
  }
  &__day-time {
    float: $right;
    & .date {
      display: inline-block;
      font-size: 25px;
      font-weight: 500;
      color: #000;
      padding-#{$left}: 10px;
      @include respond-to('small') {
        font-size: 25px;
      }
      @include respond-to('extra-small') {
        font-size: 22px;
        line-height: 1;
      }
    }
    & .time {
      display: inline-block;
      position: relative;
      font-size: 25px;
      font-weight: 300;
      color: #000;
      padding-#{$right}: 10px;
      @include respond-to('small') {
        font-size: 25px;
      }
      @include respond-to('extra-small') {
        display: block;
        padding-#{$right}: 0px;
        line-height: 1;
        font-size: 22px;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        #{$right}: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 25px;
        background-color: #000;
        @include respond-to('small') {
          height: 25px;
        }
        @include respond-to('extra-small') {
          content: none;
        }
      }
    }
  }
}

.item-button {
  position: absolute;
  #{$left}: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 160px;
  background-color: $main-red;
  @include respond-to('extra-small') {
    max-width: 140px;
  }
  &:active {
    box-shadow: inset 2px 5px 5px rgba(0, 0, 0, .15);
  }
  & a {
    font-size: 25px;
    font-weight: 500;
    color: $main-white;
    display: block;
    padding: side-values(13px 26px 13px 50px);
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
    @include respond-to('extra-small') {
      padding: side-values(8px 10px 8px 30px);
    }
  }
  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    #{$left}: 26px;
    height: 20px;
    width: 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: if-rtl(url('../images/btn-arrow.png'), url('../images/btn-arrow-right.png'));
    @include respond-to('extra-small') {
      #{$left}: 10px;
    }
  }
}

.shows-video {
  margin-bottom: 140px;
  text-align: center;
  & iframe {
    margin: 0 auto;
  }
}
