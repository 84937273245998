.t-banner-form-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px;
  background-color: $main-red;
  height: 216px;
  display: none;
  @include respond-to('medium') {
    display: none;
  }
  & .banner-form {
    text-align: center;
    &__title {
      color: $main-white;
      font-size: 24px;
      margin-bottom: 15px;
    }
  }
  & .form-group {
    margin: 0 auto;
    // @include respond-to('huge') {
    //   width: 220px;
    // }
    // @include respond-to('1100') {
    //   width: 200px;
    // }
    &_inline {
      float: $right;
      height: 40px;
    }
  }
  & .select-btn-wrap {
    display: inline-block;
  }
  & .newslatter-input {
    height: 40px;
    border: none;
    border-bottom: 1px solid $main-white;
    position: relative;
    color: $main-white;
    font-size: 22px;
    font-weight: 400;
    text-align: $right;
    padding: 8px 5px;
    @include respond-to('small') {
      margin-left: auto;
      margin-right: auto;
    }
    &::placeholder {
      color: $main-white;
      font-size: 22px;
      font-weight: 400;
      transition: opacity 0.4s ease;
    }
    &:focus {
      border-bottom-color: $main-white;
    }
    &_select {
      margin-left: 45px;
      width: 450px;
      @include respond-to('huge') {
        width: 350px;
      }
      @include respond-to('1100') {
        width: 300px;
      }
      & option {
        color: #000;
      }
    }
  }
  & .newslatter-btn {
    width: 250px;
    height: 40px;
    background:$main-white;
    color: $main-red;
    font-size: 22px;
    @include respond-to('huge') {
      width: 220px;
    }
    @include respond-to('1100') {
      width: 200px;
    }
  }
}
