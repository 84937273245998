header.scrolling{
  .header-calendar{
    top: 74px;

    &_hidden{
      top: -400px;
    }
  }
}
.header-calendar{
  width: 400px;
  height: 400px;
  background-color: #FDFBEC;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  position: absolute;
  z-index: 1;
  top: 120px;
  left: calc(50% - 600px);
  transition: .3s;

  &_hidden{
    top: -400px;
  }
}

.home-events{
  padding: 15px;

  &__title{
    font-size: 30px;
    margin-top: 0;
    text-align: center;
  }

  &__events{
    width: 300px;
    position: absolute;
    box-shadow: 0 2px 25px rgba(0,0,0,.15);
    background-color: #fff;
    z-index: 20;
    top: -10px;
    left: 40px;
    display: none;
  }
  &__date{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin: 5px;
    margin-bottom: 20px;
    color: #000;
    position: relative;

    &:after{
      width: 28%;
      height: 1px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #000;
    }
  }
  &__event{
    position: relative;
    display: block;
    padding-bottom: 10px;

    &:not(:last-of-type){
      &:after{
        width: 93%;
        height: 1px;
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #ccc;
      }
    }
  }
  &__event-title{
    color: #000;
    font-size: 18px;
    font-weight: 400;
    text-align: right;
    padding: 5px 10px;
  }
  &__time{
    width: 100%;
    height: 60px;
    position: relative;

    span{
      color: #000;
      font-weight: 300;
      font-size: 16px;
      padding: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

@media screen and (max-width: 998px) {
    .header-calendar{
        top: 70px;
        left: 0;
        width: 100%
    }
    .header-calendar_hidden{
        display: none;
    }

    .home-events__events{
    position: fixed;
    top: 68vh;
    left: 50%;
    transform: translateX(-50%);
    }

    // .calendar-item-box{
    //     position: static;
    // }

}
