.category-title {
  text-align: $right;
  font-size: 50px;
  font-weight: 300;
  line-height: 1.2;
  margin: 0 0 30px;
  @include respond-to('large') {
    font-size: 40px;
  }
  @include respond-to('small') {
    font-size: 30px;
    margin: 0 0 15px;

  }
}