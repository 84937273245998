footer {
  width: 100%;
  position: relative;
  background-color: #0e060e;
  z-index: 1;
  padding: 50px 0 20px;
  @include respond-to('large') {
    padding: 40px 0;
  }
  @include respond-to('small') {
    padding: 30px 0 20px;
  }
}

.ftr-wrap {
  @include respond-to('small') {
    text-align: center;
  }
}

.ftr-wrap-cont {
  @include respond-to('small') {
    display: inline-block;
  }
}

.ftr-cont-tel {
  text-align: $right;
  margin-top: 110px;
  @include respond-to('medium') {
    margin-top: 25px;
  }
  @include respond-to('extra-small') {
    margin-top: 15px;
  }
  &__text {
    font-size: 17px;
    color: $main-white;
    margin: 0;
    @include respond-to('extra-small') {
      font-size: 15px;
    }
  }
  &__number {
    color: $main-red;
    font-size: 38px;
    font-weight: 700;
    margin: 0;
    @include respond-to('extra-small') {
      font-size: 20px;
      font-weight: 400;
    }
  }
}

.ftr-cont-place {
  float: $right;
  margin-top: 15px;
  max-width: 250px;
  width: 100%;
  color: $main-white;
  text-align: $right;
  font-size: 17px;
  @include respond-to('extra-small') {
    margin-top: 5px;
    font-size: 15px;
  }
  &__locat {
    margin-bottom: 10px;
  }
  &__soc {
    margin-bottom: 10px;    
    & img {
      margin-#{$right}: 5px;
    }
  }
}

.hr-footer-bottom {
  border: 0;
  border-top: 1px solid $main-white;
  margin-top: 20px;
  margin-bottom: 10px;
  @include respond-to('extra-small') {
    margin-top: 10px;
  }
}

.credit-info-txt {
  display: inline-block;
  @include respond-to('medium') {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  @include respond-to('small') {
    margin-right: 0;
  }
  &>p {
    font-size: 16px;
    color: #000;
    font-weight: 300;
    margin: 0;
    @include respond-to('extra-small') {
      font-size: 14px;
    }
  }
}

.ftr-decor {
  position: absolute;
  top: -15px;
  left: 0;
  @include respond-to('larger') {
    top: -33px;
  }
  @include respond-to('medium') {
    display: none;
  }
}

// .wrap-social {
//   position: relative;
//   display: inline-block;
//   margin-#{$right}: 25px;
//   @include respond-to('large') {
//     margin-#{$right}: 20px;
//   }
//   @include respond-to('medium') {
//     display: block;
//     text-align: center;
//     margin: 0;
//     margin-bottom: 10px;
//   }
//   &>a {
//     &>svg {
//       color: #777777;
//       margin-#{$left}: 15px;
//     }
//     &:hover {
//       &>.fa-twitter {
//         color: $clr-twitter;
//       }
//       &>.fa-youtube {
//         color: $clr-youtube;
//       }
//       &>.fa-facebook-f {
//         color: $clr-facebook;
//       }
//     }
//   }
// }