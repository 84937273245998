.main-header {
  width: 100%;
  z-index: 99;
  position: fixed;
  top: 0;
  transition: all 0.4s ease;
}

.header-desktop {
  min-height: 118px;
  @include respond-to('medium') {
    display: none;
  }
}

.header-desktop-top-wrap {
  min-height: 46px;
  background-color: #000000;
  border-bottom: 1px solid #686666;
  position: relative;
  z-index: 10;
}

.header-desktop-top {
  & ul {
    float: $left;
    margin: 0;
    padding: 0;
    list-style: none;
    & li {
      display: inline-block;
      margin-#{$left}: -5px;
      padding: 0 10px;
      position: relative;
      color: $main-white;
      font-size: 16px;
      line-height: 46px;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        #{$left}: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 10px;
        background-color: $main-white;
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
      & a {
        color: $main-white;
        &:hover {
          text-decoration: none;
        }
        & img {
          margin-#{$right}: 5px;
        }
      }
    }
  }
}

.header-desktop-wrap {
  min-height: 72px;
  background-color: $main-white;
  position: relative;
  z-index: 10;

  & .section-limit {
    position: relative;
  }
}

.add-navi {
  float: $left;
  margin: 0;
  padding: 0;
  list-style: none;
  & li {
    display: inline-block;
    line-height: 72px;
    margin-#{$left}: 8px;
    & a {
      color: #000;
      text-decoration: none;
      & img {
        margin-#{$left}: 5px;
        margin-bottom: 4px;
      }
    }
  }
  & .phone-color-block {
    background-color: $main-red;
    margin-#{$left}: 0;
    & a {
      color: $main-white;
      font-size: 24px;
      font-weight: 700;
      padding: 0 10px;
      & img {
        margin-#{$left}: 0px;
        margin-#{$right}: 5px;
      }
    }
  }
}

.logo {
  position: absolute;
  top: -37px;
  #{$right}: 15px;
  @include respond-to('1100') {
    width: 150px;
    top: -10px;
  }
}

.logo-scroll {
  display: none;
}

.main-navi{
  .main-navi__parent_deep{
    &:hover {
      &::after { 
        content: "";
        position: absolute;
        // left: 50%;
        bottom: -2px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid rgba(0,0,0,.85);
      }
      .drop-menu_deep { 
        visibility: visible; 
        opacity: 1;
      }
    }
  }
}


.main-navi {
  float: $left;
  margin-#{$left}: 70px;
  height: 72px;
  @include respond-to('large') {
    margin-#{$left}: 10px;
  }
  &>ul {
    display: inline-block;
    list-style: none;
    margin: side-values(0px 0px 0 0);
    padding: 0;
    &>li {
      float: $right;
      line-height: 72px;
      // position: relative;

      &.main-navi__parent{
        &:hover {
          &::after {
            content: "";
            position: absolute;
            // left: 50%;
            bottom: -2px;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid rgba(0,0,0,.85);
          }
          .drop-menu {
            visibility: visible;
            opacity: 1;
           
          }
        }
      }

         

      &>a {
        font-size: 20px;
        color: #000;
        padding: 3px 12px;
        @include respond-to('large') {
          padding: 3px 8px;
        }
        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          color: $main-red;
        }
        & .fa-caret-down {
          margin-#{$right}: 5px;
        }
      }
    }
  }
}




.drop-menu {
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s ease;
  position: absolute;
  top: 74px;
  left: 50%;
  transform: translateX(-50%);
  width: 3000px;
  min-height: 80px;
  margin: 0 auto;
  padding: 0;
  background-color: rgba(0, 0, 0, .85);
  text-align: center;






  @include respond-to('large') {
    min-height: 100px;
  }
  & li {
    display: inline-block;
    list-style: none;
    &:first-child {
      & a {
       padding-#{$right}: 50px;
      }
    }
    &:last-child {
      & a {
        padding-#{$left}: 0;
        &::after {
          content: none;
        }
      }
    }
    & a {
      position: relative;
      color: $main-white;
      font-size: 25px;
      font-weight: 100;
      padding: 0 20px;
      @include respond-to('large') {
        font-size: 24px;
        font-weight: 300;
        padding: 0 10px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        #{$left}: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 25px;
        background-color: $main-white;
      }
      &:hover,
      &:focus,
      &:active {
        color: $main-red;
        text-decoration: none;
      }
    }
  }
}

.drop-menu_deep {
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s ease;
  position: absolute;
  top: 74px;
  left: 50%;
  transform: translateX(-50%);
  width: 3000px;
  min-height: 80px;
  margin: 0 auto;
  padding: 0;
  background-color: rgba(0, 0, 0, .85);
  text-align: center;






  @include respond-to('large') {
    min-height: 100px;
  }
  & li {
    display: inline-block;
    list-style: none;
    &:first-child {
      & a {
       padding-#{$right}: 50px;
      }
    }
    &:last-child {
      & a {
        padding-#{$left}: 0;
        &::after {
          content: none;
        }
      }
    }
    & a {
      position: relative;
      color: $main-white;
      font-size: 25px;
      font-weight: 100;
      padding: 0 20px;
      @include respond-to('large') {
        font-size: 24px;
        font-weight: 300;
        padding: 0 10px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        #{$left}: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 25px;
        background-color: $main-white;
      }
      &:hover,
      &:focus,
      &:active {
        color: $main-red;
        text-decoration: none;
      }
    }
  }
}
.li-wraper {
  width: 100%;
  max-width: 1230px;
  margin: 61px auto;
  padding: 0 15px;
  text-align: center;
  @include respond-to('large') {
    margin: 15px auto;
  }
}

.header-mobile {
  display: none;
  height: 70px;
  @include respond-to('medium') {
    display: block;
  }
  @include respond-to('small') {
    background-color: $main-white;
  }
  &__logo {
    margin-left: -15px;
    margin-right: 15px;
    margin-top: 5px;
    float: $left;
    &>img {
      width: 180px;
    }
  }
  &__tel {
    display: inline-block;
    margin-top: 17px;
    svg {
      color: $main-red;
      font-size: 32px;
    }
  }
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  background-color: $main-red;
}

.main-header.scrolling {
  .header-desktop-top-wrap {
    display: none;
  }
  .header-desktop {
    min-height: 70px;
    border-bottom: 1px solid rgba(163, 156, 146, 0.4);
  }
  .header-mobile {
    background-color: $main-white;
    border-bottom: 1px solid hsla(35, 8%, 61%, .4);
  }
  .main-navi {}
  .logo {
    display: none;
  }
  .logo-scroll {
    display: block;
    @include respond-to('1100') {
      & img {
        width: 180px;
        margin-top: 14px;
      }
    }
  }
}

/*.main-navi > ul > li.main-navi__parent .drop-menu:first-child{
    left: 200% !important;
}*/
