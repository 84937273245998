.wrap-article-box{
  display: block;
  &:hover{
    .article-box-image__item{
      transform: scale(1.07);
    }
  }
}

.article-box-image{
  width: 100%;
  height: 149px;
  overflow: hidden;
}

.article-box-image__item{
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 149px;
  transition: all 0.6s;
}

.article-box-data__title{
  font-size: 24px;
  font-weight: 800;
  color: #415667;
  line-height: 1;
  margin-bottom: 25px;
  @include respond-to('extra-small'){
    font-size: 22px;
    margin: 10px 0 15px;
  }
}

.article-box-data__content{
  //min-height: 95px;
  //max-height: 105px;
  overflow: hidden;
  text-align: justify;
  & > p{
    font-size: 18px;
    color: #415667;
    margin: 0;
    line-height: 1.2;
  }
}

.article-box__btn{
    width: 50%;
  display: inline-block;
  font-size: 18px;
  color: #1965ad;
  font-weight: 800;
  padding-top: 8px;
  border-top: 2px solid #1965ad;
  margin-top: 30px;
  &:hover,&:active,&:focus{
    color: #1965ad;
  }
}
