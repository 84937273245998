/**
*
*
*/

body.blackandwhite {
    color:#000 !important;
    -webkit-filter: grayscale(1) !important;
    -moz-filter: grayscale(1) !important;
    -ms-filter: grayscale(1) !important;
    -o-filter: grayscale(1) !important;
    filter: grayscale(1) !important;
}


body.blackandwhite *{
    color:#000 !important;
}

body.heaveysightedblack{
    color: #fff !important;
    background:none !important;
    background-color: #000 !important;
}

body.heaveysightedblack *{
    color: #fff !important;
    background-color: #000 !important;
    box-shadow:none !important;
}

body.heaveysightedblack .simply_credit {
    background: rgba(0, 0, 0, 0) url("../images/simply-white.png") no-repeat scroll 0 0;
}

body.heaveysightedblack a{
    outline:1px dotted yellow !important;;
}

body.heaveysightedwhite{
    color: #000 !important;
    background:none !important;
    background-color: #fff !important;
    box-shadow:none !important;
}

body.heaveysightedwhite *{
    color: #000 !important;
   /* background:none !important;*/
    background-color: #fff !important;
    box-shadow:none !important;
}
body.heaveysightedwhite .simply_credit {
    background: rgba(0, 0, 0, 0) url("../images/simply-black.png") no-repeat scroll 0 0;
}



body.heaveysightedwhite a{
    outline:1px dotted #000 !important;;
}


body.heaveysightedwhite .accessibility_wrapper .mainNmenu *,
body.heaveysightedwhite .accessibility_wrapper .mainNmenu *{
    color:#000 !important;
    box-shadow:none
}

body.blackandwhite .accessibility_wrapper .mainNmenu *:focus,body.blackandwhite .accessibility_wrapper .mainNmenu *:hover,
body.heaveysightedwhite .accessibility_wrapper .mainNmenu *:focus,body.heaveysightedwhite .accessibility_wrapper .mainNmenu *:hover,
body.heaveysightedwhite .accessibility_wrapper .mainNmenu *:focus,body.heaveysightedwhite .accessibility_wrapper .mainNmenu *:hover{
    color:#000 !important;
}

#quickMenu {
    background: rgba(0, 0, 0, 0.75) none repeat scroll 0 0;
    border-radius: 6px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0);
    line-height: 40px;
    max-width: 80vw;
    overflow: hidden;
    position: absolute;
    right: 285px;
    top: -100px;
    width: auto;
    min-width: 680px;
}
body.with_js #quickMenu{
    top: -6px;
    opacity: 0;
    _transition: all 0.5s;
    pointer-events: none;
}
body.with_js #quickMenu.focusin{
    opacity: 1;
    pointer-events: initial;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
}
#quickMenu > div {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    display: table;
    height: 90%;
    line-height: 40px;
    margin: 2px 1%;
    width: 98%;
}

#quickMenu a {
    color: #000;
    cursor: pointer;
    display: table-cell;
    font-family: arial;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    vertical-align: middle;
    padding: 7px 5px;
}
#quickMenu a:hover , #quickMenu a:focus  {
    /*background:#ffffcc !important;*/
    box-shadow: 0 0 6px 3px #ffff00, 0 0 1px 1px #f00 inset !important;
    border-radius: 5px !important;
    color: #bf1722 !important;
    outline: medium none !important;
    text-shadow: 1px 1px 1px #bbb;
    outline-style: inset;
    outline-color: red;

    transition: color 0.2s ease-in 0s, text-shadow 0.2s ease-in 0s;
}

.accessibility *:focus  {
    /*background:#ffffcc !important;*/
    box-shadow: 0 0 6px 3px #ffff00, 0 0 1px 1px #f00 inset !important;
    border-radius: 5px !important;
    color: #bf1722 !important;
    outline: medium none !important;
    text-shadow: 1px 1px 1px #bbb;
    outline-style: inset;
    outline-color: red;

    transition: color 0.2s ease-in 0s, text-shadow 0.2s ease-in 0s;
}

.accessibilityLinks a.selected , .accessibilityLinks a span.selected{
    /*background:#ffffcc !important;*/
    box-shadow: 0 0 6px 3px #ffff00, 0 0 2px #333 inset !important;
    border-radius: 5px !important;
    color: #bf1722 !important;
    outline: medium none !important;
    text-shadow: 1px 1px 1px #bbb;
    border: 1px solid red;
    outline-style: inset;
    outline-color: red;

    transition: color 0.2s ease-in 0s, text-shadow 0.2s ease-in 0s;
}

.accessibility *:focus *  {
    color: #bf1722 !important;
}


.desktop.accessibility_wrapper {
    position: fixed;
    right: 5px;
    top: 165px;
    z-index: 100;
    transition: .3s;
}

body.scrolling{
  .desktop.accessibility_wrapper{
    top: 100px;
  }
}

.accessibility_menu > div.mainNmenu > a {
    background: #fff none repeat scroll 0 0 !important;
    border: 2px solid rgba(39, 74, 188, 1) !important;
    border-radius: 50% !important;
    color: rgba(39, 74, 188, 1) !important;
    cursor: pointer !important;
    font-size: 18px !important;
    padding: 1px !important;
    height: 42px !important;
    width: 42px !important;
    display: block;
}

body.heaveysightedwhite .accessibility_menu > div.mainNmenu > a {
    background: #333 !important;
    color: #fff !important;
}
body.heaveysightedwhite .tota11y-toolbar *{
    background-color: #333!important;
    color: #f2f2f2!important;
}

#accessibilityLink{
    padding: 5px 10px;

    .fa{
      font-size: 36px;
    }
 
    svg,path{
      width: 100% !important;
      height: 100% !important;
    }
}
.accessibility_menu > div.mainNmenu a{
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px #333;
    color: #000;
    padding: 0 10px;
    cursor: pointer;
    text-decoration: none;
}
.accessibility_menu > div a:hover{
    background: #fff none repeat scroll 0 0;
    border-color: #000;
    color: #000;
}
#accessibilityLink:focus{
    /*background: #ffffcc;*/
    box-shadow: 0 0 6px 3px #ffff00, 0 0 2px #333 inset;
    color: #000;
}
.accessibilityLinks {
    font-size: 13px;
    margin-top: 10px;
    position: absolute;
    right: -240px;
    width: 230px;
}

.accessibilityLinks > a {
    display: block;
    height: 40px;
    line-height: 40px;
    margin: 4px 0;
    padding: 0 9px 0 0;
    border: 1px solid #fff;
}


.fa.fa-font:hover{
    color:#999;
}
.fa.font_xl {
    font-size: 28px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    margin: 0 5px;
}
.fa.font_l {
    font-size: 22px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    margin: 0 5px;
}
.fa.font_s {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    margin: 0 5px;
}

/**************OPEN MAIN SUB MENU ***************/

#menu > .pagewraper  li:focus > ul{
    display:block;
}

/***********************************************/
.hidden_label {
    width: 1px;
    height: 1px;
    font-size: 1px;
    overflow: hidden;
    display: inline-block;
    position: absolute;
}
.hidden_text {
    font-size: 0px;
}
.sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}.sr-only-focusable:active,.sr-only-focusable:focus{position:static;width:auto;height:auto;margin:0;overflow:visible;clip:auto}

.mainNmenu {
    position: relative;
    top: 0px;
}

.blackandwhite #welcome > .pagewrapper ,.blackandwhite #welcome2 , .blackandwhite div#header_text > div {
    background: #999 none repeat scroll 0 0;
}

.heaveysightedblack #rotate {
    color: #fff !important;
    z-index: 1;
}



@media (max-width: 768px){
    .accessibility-text{
        display: none;
    }

    #accessibilityLink{
      width: 42px !important;
      height: 42px !important;
      font-size: 30px !important;
      display: block;
      //border-top-left-radius: 50% !important;
      //border-bottom-left-radius: 50% !important;
      padding-right: 1px !important;
      margin-right:0px !important;

    }

    .desktop.accessibility_wrapper {
      position: fixed;
      right: 5px;
      top: 65px !important;
      z-index: 4;
    }

    body.scrolling{
      .desktop.accessibility_wrapper{
        top: 100px;
      }
    }

    .hide-accessibility-link-mobile{
      display:none !important;
    }

    .shortcut_menu{
      display:none;
    }
}
