.wrapper-all-content {
  & .banner-form-wrap {
    position: relative;
    top: -78px;
  }
}

.wrap-content {
  padding: 0;
}

.content-text {
  text-align: center;
  margin-bottom: 100px;
  @include respond-to('medium') {
    margin-bottom: 25px;
  }
  @include respond-to('extra-small') {
    margin-bottom: 0px;
  }
  h2, h3, {
    text-align: $right;
    font-size: 50px;
    font-weight: 300;
    line-height: 1.2;
    margin: 0 0 30px;
    @include respond-to('large') {
      font-size: 40px;
    }
    @include respond-to('extra-small') {
      font-size: 30px;
      margin: 0 0 15px;
    }
  }
  p {
    text-align: $right;
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    padding-#{$left}: 200px;
    margin-bottom: 30px;
    @include respond-to('medium') {
      padding-#{$left}: 0px;
      line-height: 1.6;
      margin-bottom: 30px;
    }
    @include respond-to('extra-small') {
      font-size: 15px;
      text-align: justify;
      margin-bottom: 15px;
    }
  }
  iframe {
    @include respond-to('small') {
      text-align: center !important;
      margin: 15px auto !important;
      width: 100% !important;
      height: auto !important;
      float: none !important;
      display: block !important;
    }
  }
  img {
    text-align: center;
    margin: 5px 2px;
    @include respond-to('small') {
      float: none !important;
      text-align: center !important;
      width: auto !important;
      height: auto !important;
      margin: 15px auto !important;
    }
    @include respond-to('extra-small') {
      margin: 5px auto !important;
    }
  }
}

.content-big-banner {
  margin-bottom: 110px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 756px;
  position: relative;
  z-index: 2;
}

.content-big-banner-img {
  margin-bottom: 30px;
  display: block;
  @include respond-to('extra-small') {
    margin-bottom: 15px;
  }
}

.minh{
        //min-height: 800px;
}
