.hp-form {
  @include respond-to('small') {
    text-align: center;
    margin-bottom: 20px;
  }
  .has-feedback .form-control {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.js-validate-form{

  .msg-success{
    width: 400px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    box-shadow: 10px 10px 30px rgba(0,0,0,.2);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%) scale(1);
    z-index: 1;
    font-size: 22px;
    font-weight: 500;
    border: 1px solid #000;
    opacity: 1;
    transition: .5s;

    &_hidden{
      opacity: 0;
      z-index: -1;
      transform: translate(-50%,-50%) scale(.95);
    }
  }
}

.form-group-wrap {
  text-align: center;
}

.form-group {
  margin: 0 auto;
  text-align: center;
  &_inline {
    display: inline-block;
    margin-#{$left}: 45px;
    margin-bottom: 55px;
    @include respond-to('small') {
      display: block;
      margin-#{$left}: auto;
      margin-#{$right}: auto;
      margin-bottom: 15px;
    }
    &:last-child {
      margin-#{$left}: 0px;
    }
  }
}

.newslatter-input {
  width: 200px;
  height: 42px;
  border: 1px solid #000;
  background-color: transparent;
  position: relative;
  color: #000;
  font-size: 17px;
  font-weight: 300;
  text-align: center;
  padding: 5px 15px;
  border-radius: 0;
  box-shadow: none;
  @include respond-to('small') {
    margin-left: auto;
    margin-right: auto;
  }
  &:focus {
    outline: none;
    &::placeholder {
      opacity: 0;
    }
  }
  &::placeholder {
    color: #000;
    font-size: 17px;
    font-weight: 300;
    transition: opacity 0.4s ease;
  }
}

.newslatter-btn {
  display: block;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 38px;
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  border: 0;
  background-color: $main-red;
  position: relative;
  @include respond-to('small') {
    margin-left: auto;
    margin-right: auto;
  }
  &:focus {
    outline: none;
  }
  &:active {
    @include btn-effect-active;
  }
  &>svg {
    font-size: 18px;
    margin-#{$right}: 7px;
    transition: all 0.4s;
  }
  &:hover {
    &>svg {
      transform: translateX(-4px);
    }
  }
}

.hp-check-uncheck {
  position: relative;
  top: -6px;
  width: 23px;
  height: 23px;
  display: inline-block;
  margin-#{$left}: 5px;
  label {
    width: 23px;
    height: 23px;
    cursor: pointer;
    position: absolute;
    background-color: #eeeeee;
    border: 1px solid #bebebe;
    #{$left}: 0;
    top: 0;
    &:after {
      content: '';
      width: 12px;
      height: 6px;
      position: absolute;
      top: 6px;
      #{$left}: 5px;
      border: 2px solid #000;
      border-top: none;
      border-right: none;
      background: 0 0;
      opacity: 0;
      transform: rotate(-45deg);
    }
  }
  input[type=checkbox] {
    visibility: hidden;
    &:checked+label:after {
      opacity: 1;
    }
  }
}

.hp-checkbox-para {
  display: inline-block;
  max-width: 170px;
  width: 100%;
  text-align: $right;
  &>p {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    margin: 0;
    @include respond-to('larger') {
      font-size: 12px;
    }
  }
}
