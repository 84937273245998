.banner-button-block-wrap {
  position: absolute;
  top: 61%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1000px;
  width: 100%;
  @include respond-to('large') {
    top: 68%;
  }
  @include respond-to('medium') {
    display: none;
  }
}

.banner-button-block {
  margin: 0 auto;
  max-width: 700px;
  width: 100%;
  @include respond-to('large') {
    max-width: 585px;
  }
}

.banner-button-block-item {
  float: #{$right};
  width: 158px;
  height: 158px;
  background-color: rgba(0, 0, 0, .8);
  text-align: center;
  padding: 27px 35px;
  text-decoration: none;
  margin-#{$left}: 20px;
  @include respond-to('large') {
    width: 138px;
    height: 138px;
    padding: 27px 35px;
    margin-#{$left}: 10px;
  }
  &:last-child {
    margin-#{$left}: 0px;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(224, 40, 67, .8);
    text-decoration: none;
  }
  &__icon {
    pointer-events: none;
  }
  &__text {
    margin-top: 15px;
    color: $main-white;
    font-weight: 500;
    line-height: 1.1;
    pointer-events: none;
  }
}

.active-button {
  background-color: $main-red;
  &:hover {
    background-color: $main-red;
  }
}