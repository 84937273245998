@charset 'UTF-8';

$dir: rtl !default;

@import
  'abstracts/functions',
  'abstracts/variables',
  'abstracts/variables-base64',
  'abstracts/mixins';

@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/accessibility/accessibility.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/banner-buttons/banner-buttons.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/banner-form-star/banner-form-star.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/banner-form-ticket/banner-form-ticket.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/breadcrumbs/breadcrumbs.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/hp-form/hp-form.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/inner-banner/inner-banner.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/ov--calendar/ov--calendar.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/pages-title/pages-title.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/popup/popup.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/side-menu/side-menu.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/side-navigation/side-navigation.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/site-footer/site-footer-credit/site-footer-credit.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/site-footer/site-footer-links/site-footer-links.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/site-footer/site-footer.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/site-header/header-calendar/header-calendar.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/site-header/site-header.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/blocks_shared/social-share/social-share-btns.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/layout/base-layout/base-layout.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/layout/page-with-side-layout/page-with-side-layout.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/article/article-category-box/article-category-box.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/article/article-category.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/article/article-item-box/article-item-box.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/article/article-item.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/catalog/catalog-item/catalog-item.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/catalog/catalog-page.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/contact-us/form-elements.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/content/content-page.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/homepage/homepage-box/homepage-box.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/homepage/homepage-slider/homepage-slider.scss";
@import "C:/xampp/htdocs/websites/modiin.co.il.php/app/resources/pages/homepage/homepage.scss";
