.side-naw-wrap {
  margin: 90% 0 10%;
      height: auto;
  z-index: 1;
  transition: top .4s ease;
  @include respond-to('medium') {
    display: none;
  }
  & ul {
    list-style: none;
    margin: 0;
    padding: 0 10px;
    border-#{$right}: 3px solid #f1ca12;
    & li {
      margin-bottom: 5px;
      & a {
        color: #545454;
        font-size: 14px;
        &:hover,
        &:active,
        &:focus {
          color: #f1ca12;
          text-decoration: none;
        }
      }
    }
  }
  &_selected{
    color: #f1ca12 !important;
    font-weight: 800;
  } //_selected
}
