$calendar-item-box__margin: 5px;
$calendar__ratio: 5 / 7 ;

//styles calendar header
$calendar-header-color: #fff;
$wrap-calendar-header-navi__years_text-color: #000;
$wrap-calendar-header-navi_btn-color: #f4771b;;
$wrap-calendar-header-navi__years_text-size: 20px;
$wrap-calendar-header-navi__years_text-weight: 800;

// backgrounds color days
$day-current: #000;
$day-events: #e02843;

//styles weeks
$calendar-weeks_text-color: #fff;
$calendar-weeks_text-size: 18px;

//styles days
$calendar-day_text-color: #777;
$calendar-day_text-size: 22px;
$calendar-day_text-weight: 600;
$day-current-text-color: #fff;
$day-mask: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAoCAIAAAAt2Q6oAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNrs1TEKgDAMheEaJGum3v9GuUvXTgZcpEhXE/zdHkkhDo/vcHczU9XW2pxzjJE/SrmLI0q5iyNKuYsjSrmLI8ozxLdZzTM9l9Xe+733+q9JpmsRNy/zTCniJ0VERERERERERESkiIiIiIiIiIiIiBQRERERERERERHx90W8BBgAx5L4he4oWJAAAAAASUVORK5CYII=');

$calendar-item-box-bg: transparent;

//styles calendar popap
$calendar-popap_color-bg: #fff;
$calendar-popap_arrow-color-bg: #fff;
$calendar-popap-header__datetimes_text-size: 14px;
$calendar-popap-header__datetimes_text-color: #000;
$calendar-popap-header__hr-color: #d3d3d3;
$calendar-popap-header__title_text-size: 18px;
$calendar-popap-header__title_text-color: #00b5a2;
$calendar-popap-header__title_text-weight: 800;
$calendar-popap-body__content_text-size: 14px;
$calendar-popap-body__content_text-color: #000;
$calendar-popap__tongue_text-color: #fff;
$calendar-popap__tongue_color-bg: #00b5a2;
$calendar-popap-body__hr_bg-color: #00b5a2;

@mixin aspect-ratio($ratio) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($ratio) * 100%;
  }
  > .calendar-item-box__wrap-data  {
    position: absolute;
    top: $calendar-item-box__margin;
    left: $calendar-item-box__margin;
    right: $calendar-item-box__margin;
    bottom: $calendar-item-box__margin;
    background-color: #fff;
  }
}

//calendar header
.wrap-calendar-header{
  min-height: 50px;
  margin-bottom: 30px;
  background-color: $calendar-header-color;
}

.wrapper-calendar{
  position: relative;
  z-index: 2;
}

.wrap-calendar-header-navi_style-btn{
  cursor: pointer;
  padding: 10px;
  &:hover{
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
  }
  &:active{
    box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.4);
  }
}

.wrap-calendar-header-navi_btn-color{
  color: $wrap-calendar-header-navi_btn-color;
}

.wrap-calendar-header-navi{
  line-height: 50px;
  text-align: center;
}

.wrap-calendar-header-navi__years{
  color: $wrap-calendar-header-navi__years_text-color;
  display: inline-block;
  font-size: $wrap-calendar-header-navi__years_text-size;
  font-weight: $wrap-calendar-header-navi__years_text-weight;
  vertical-align: baseline;
  margin: 0 12px;
}//END calendar header

//calendar body
.calendar-body{
  margin: 0;
  padding: 0;
  list-style: none;
}

.calendar-item-box{
  float: right;
  width: 14.285%;
  position: relative;
  background-color: $calendar-item-box-bg;
  @include aspect-ratio($calendar__ratio);
}

.calendar-item-box__content{
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  color: #000;

  &> span{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.calendar-popap-body__hr{
  margin: 5px 0 8px;
  border-top: 1px solid $calendar-popap-body__hr_bg-color;
}

.calendar-weeks{
  padding: 0;
  list-style: none;
  margin: 0;
  .calendar-item-box__wrap-data{
    background-color: transparent !important;
  }
}

.calendar-weeks_text-style{
  color: $calendar-weeks_text-color;
  font-size: $calendar-weeks_text-size;

  @include respond-to('medium'){
    font-size: 12px;
  }
  @include respond-to('small'){
    font-size: 16px;
  }
  @include respond-to('extra-small'){
    font-size: 10px;
  }
}

.calendar-item-box_text-style{
  font-size: $calendar-day_text-size;
  color: $calendar-day_text-color;
  font-weight: $calendar-day_text-weight;

  @include respond-to('extra-small'){
    font-size: 12px;
  }
}

.day-current{
  background-color: #000;
}

.day-current-text{
  color: $day-current-text-color;
}

.day-events{
  background-color: #e02843;
  cursor: pointer;
  height:100%;

  &:hover > .calendar-popap{
    display: block;
  }
}

.day-mask{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: $day-mask;
  /*width: 60px;
  height: 40px;*/
  width: 100%;
  height: 100%;
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    @include respond-to('extra-small'){
      width: 45px;
      height: 25px;
    }
}//END calendar body

// calendar popap
.calendar-popap{
  background-color: $calendar-popap_color-bg;
  width: 290px;
  min-height: 145px;
  padding: 12px 20px;
  position: absolute;
  z-index: 5;
  display:none;
  box-shadow: 0px 0px 30px 0 rgba(0,0,0,0.2);
}

.calendar-popap--default-position{
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  left: 100%;
  &:before{
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 10px 10.5px 0;
    border-color: transparent $calendar-popap_arrow-color-bg transparent transparent;
    line-height: 0px;
    _border-color: #000000 $calendar-popap_arrow-color-bg #000000 #000000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -10px;
    z-index: 100;
  }
}

.calendar-popap--top-position{
  bottom: 100%;
  right: -100%;
  &:before{
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 10.5px 0 10.5px;
    border-color: $calendar-popap_arrow-color-bg transparent transparent transparent;
    line-height: 0px;
    _border-color: $calendar-popap_arrow-color-bg #000000 #000000 #000000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    position: absolute;
    bottom: -10.5px;
    left: 0;
    right: -21px;
    margin: 0 auto;
    z-index: 100;
    z-index: 100;
  }
}

.calendar-popap-header__datetimes{
  font-size: $calendar-popap-header__datetimes_text-size;
  color: $calendar-popap-header__datetimes_text-color;
}

.calendar-popap-header__hr{
  border-top: 1px solid $calendar-popap-header__hr-color;
  margin: 10px 0;
}

.calendar-popap-header__title{
  color: $calendar-popap-header__title_text-color;
  font-size: $calendar-popap-header__title_text-size;
  font-weight: $calendar-popap-header__title_text-weight;
  line-height: 1;
}

.calendar-popap-body{
  margin-bottom:10px;
}

.calendar-popap-body__content{
  font-size: $calendar-popap-body__content_text-size;
  color: $calendar-popap-body__content_text-color;
}

.calendar-popap__tongue{
  position: absolute;
  bottom: -1px;
  left: -1px;
  z-index: 100;
  width: 25px;
  height: 25px;
  text-align: center;
  padding-top: 1px;
  color: $calendar-popap__tongue_text-color;
  padding-right: 7px;
  &:before{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 0 25px;
    border-color: transparent transparent transparent $calendar-popap__tongue_color-bg;
    line-height: 0px;
    _border-color: #000000 #000000 #000000 $calendar-popap__tongue_color-bg;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
    position: absolute;
    bottom: 1px;
    left: 1px;
    content: "";
    z-index: -1;
  }
}// END calendar popap

.big-calendar{
  .day-mask{
    width: 100%;
    height: 100%;
  }
  .wrap-calendar-header{
    box-shadow: 0 0 20px rgba(0,0,0,.15);
    position: relative;
    top: -25px;
    margin-bottom: 0;
  }
  .calendar-weeks{
    &_text-style{
      color: #000;
      &:before{
        padding-top: 20%;
      }
    }
  } //calendar-weeks
  .calendar-item-box{
    &__day{
      position: absolute;
      #{$left}: 10px;
      top: 5px;
      font-weight: 300;

      @include respond-to('medium'){
        #{$left}: 5px;
        top: 0px;
      }
      @include respond-to('small'){
        font-size: 10px;
      }
    }
    &__event{
      color: #fff;
      font-weight: 300;
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: block;
      font-size: 16px;
      text-align: right;
      width: 100%;

      @include respond-to('medium'){
        #{$right}: 5px;
        bottom: 5px;
        font-size: 12px;
      }
      @include respond-to('small'){
        font-size: 10px;
        line-height: 12px;
      }
      @include respond-to('extra-small'){
        display: none;
      }
    }
  } //calendar-item-box
} //big-calendar


.home-events__event-image img{
    margin-right: 5%;
    margin-top: 5%;
}
.home-events__date{
    margin: 14px !important;
}
.home-events__event{

    .col-xs-7{
        .col-xs-12{
            padding-right: 0 !important;
            .home-events__event-title{
                padding-right: 0 !important;
                padding-top: 0 !important;

            }
        }
    }
}
