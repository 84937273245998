.homepage-box {
  position: relative;
  display: block;
  max-width: 375px;
  width: 100%;
  max-height: 395px;
  height: 100%;
  color: #fff;
  overflow: hidden;
  @include respond-to('medium') {
    margin: 0 auto 25px;
  }
  @include respond-to('mobile-l') {
    margin: 0 auto 15px;
  }
  &:hover {
    & .hover-shadov {
      opacity: 1;
    }
    & .homepage-box-info {
      display: none;
    }
  }
  &:focus {
    color: #fff;
    text-decoration: none;
  }
  &__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #000 20%, transparent);
    opacity: .5;
  }
  &__image {
    overflow: hidden;
    text-align: center;
    img {
      transition: transform 200ms;
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 900;
    margin: 0 0 10px;
    @include respond-to('mobile-l') {
      font-size: 20px;
      font-weight: 400 ;
    }
  }
  &__description {
    font-size: 15px;
    padding-#{$left}: 45px;
    @include respond-to('mobile-l') {
      padding-#{$left}: 0px;
    }
  }
  &__date {
    float: $right;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    @include respond-to('mobile-l') {
      font-size: 20px;
      font-weight: 400 ;
    }
  }
  &__time {
    float: $left;
    font-size: 20px;
    margin-top: 14px;
    line-height: 1;
    @include respond-to('mobile-l') {
      margin-top: 1px;
    }
  }
}

.homepage-box-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 40px;
  @include respond-to('mobile-l') {
    padding: 10px;
  }
}

.hover-shadov {
  position: absolute;
  top: 0;
  #{$left}: 0;
  width: 100%;
  height: 100%;
  transition: opacity 500ms;
  opacity: 0;
  background-color: rgba(224, 40, 67, 0.75);
  &__plus {
    position: absolute;
    top: 31%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('../images/plus.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 58px;
    height: 58px;
    &_top50 {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__text {
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 19px;
    font-weight: 500;
    color: $main-white;
  }
}
