.banner-top-content {
  position: relative;
  width: 100%;
  min-height: 600px;
  @include respond-to('small') {
    min-height: inherit;
  }
}

.banner-top-content__image {
  background-position: center center;
  background-size: cover;
  min-height: 600px;
}

.banner-title {
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1200px;
  max-height: 164px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  & h1 {
    margin: 0;
    font-size: 115px;
    font-weight: 400;
    color: $main-white;
    @include respond-to('small') {
      font-size: 50px;
    }
    @include respond-to('extra-small') {
      font-size: 35px;
    }
  }
}